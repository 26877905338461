import React from "react";
import styled from "styled-components";
import Head from "./Head";
import Context from "./Context";

const Domain = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px 0;
  grid-column-gap: 24px;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    grid-template-columns: 1fr;
    padding: 24px 0;
  }
`;

const Column = styled.div`
  grid-column: span 1;
`;

const Layout = ({ left, right }) => (
  <Domain>
    <Column>{left}</Column>
    <Column>{right}</Column>
  </Domain>
);

export const PageLayout = ({ children, slug }) => (
  <Context>
    <Head slug={slug} />
    {children}
  </Context>
);

export default Layout;
