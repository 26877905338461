import React from "react";
import styled from "styled-components";
const Line = styled.div`
  background-color: var(--element-muted-color);
  height: 1px;
`;

const LineSpace = styled.div`
  height: 4px;
`;

const DoubleLine = () => (
  <div>
    <Line />
    <LineSpace />
    <Line />
  </div>
);
export default DoubleLine;
