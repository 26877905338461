import * as React from "react";

const Logo = (props) => (
  <svg
    {...props}
    viewBox="0 0 364 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="path-1"
        points="0.6067 35 33.0277 35 33.0277 0.3143 0.6067 0.3143"
      />
      <polygon id="path-3" points="0 47 363.608 47 363.608 0.083 0 0.083" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none">
      <g>
        <path
          d="M5.112,7.213 L10.678,7.213 L10.678,1 L5.112,1 L5.112,7.213 Z M0,13.231 L0,17.373 L5.824,17.373 L5.824,43.775 L0,43.775 L0,47.852 L16.049,47.852 L16.049,43.775 L10.483,43.775 L10.483,13.231 L0,13.231 Z"
          id="Fill-1"
          fill="var(--title-color)"
        />
        <g id="Group-20" transform="translate(0.000000, 0.917000)">
          <g id="Group-4" transform="translate(19.000000, 12.000000)">
            <g id="Clip-3" />
            <path
              d="M22.8027,27.8173 L22.8027,18.7563 L12.4487,18.7563 C8.4367,18.7563 7.3367,19.1453 6.4947,19.9863 C5.6537,20.8923 5.3307,21.9273 5.3307,24.7753 C5.3307,27.8173 5.6537,29.2403 6.3657,29.9523 C7.1427,30.6643 8.1777,30.9223 10.1837,30.9223 L13.9367,30.9223 C17.0437,30.9223 18.9847,30.2113 22.8027,27.8173 M21.8967,5.2323 C21.3147,4.6493 20.6027,4.3903 18.2727,4.3903 L12.0607,4.3903 C9.8607,4.3903 8.7607,4.6493 8.0487,5.3613 C7.2067,6.2033 6.8837,7.5613 6.8187,10.6683 L2.1597,10.6683 C2.2887,5.5553 2.9357,3.6783 4.3597,2.1903 C5.7827,0.7673 7.4657,0.3143 10.7017,0.3143 L19.1147,0.3143 C22.8677,0.3143 24.4207,0.8963 25.5857,2.1263 C27.1387,3.6143 27.4617,5.4913 27.4617,10.0203 L27.4617,30.8583 L33.0277,30.8583 L33.0277,34.9353 L22.8027,34.9353 L22.8027,30.5353 C18.3377,34.1593 16.6557,35.0003 13.2257,35.0003 L9.1487,35.0003 C6.0427,35.0003 4.1007,34.4823 2.6767,33.0583 C1.4477,31.8293 0.6067,29.8233 0.6067,25.4223 C0.6067,21.2813 1.3827,18.8223 2.7417,17.4623 C4.2307,15.9743 6.6247,15.1323 12.6427,15.1323 C15.7487,15.1323 19.3727,15.3273 22.8027,15.9103 L22.8027,10.2803 C22.8027,6.8493 22.5437,5.8793 21.8967,5.2323"
              id="Fill-2"
              fill="var(--title-color)"
            />
          </g>
          <path
            d="M67.0376,16.9091 C71.5676,13.2201 73.1856,12.2491 76.8736,12.2491 L80.6266,12.2491 C83.7986,12.2491 85.2866,12.8321 86.5806,14.0611 C87.2926,14.8381 87.8106,15.8081 88.0696,17.1681 C92.9226,13.2851 94.5406,12.2491 98.2286,12.2491 L101.9826,12.2491 C105.2176,12.2491 106.6416,12.8321 107.9356,14.0611 C109.2946,15.4201 109.7476,17.2971 109.7476,20.9851 L109.7476,42.8581 L115.3126,42.8581 L115.3126,46.9351 L99.7176,46.9351 L99.7176,42.8581 L105.1536,42.8581 L105.1536,21.2441 C105.1536,19.2381 104.8296,18.2031 104.1176,17.4271 C103.4056,16.7151 102.6946,16.4561 100.1706,16.4561 L96.8706,16.4561 C94.0226,16.4561 91.9516,17.2321 88.3276,19.5611 C88.3926,20.0141 88.3926,20.4681 88.3926,20.9851 L88.3926,42.8581 L93.8286,42.8581 L93.8286,46.9351 L78.3626,46.9351 L78.3626,42.8581 L83.7336,42.8581 L83.7336,21.2441 C83.7336,19.2381 83.4746,18.2031 82.6976,17.4271 C81.9866,16.7151 81.2746,16.4561 78.7506,16.4561 L75.5156,16.4561 C72.6026,16.4561 70.6616,17.2321 67.0376,19.5611 L67.0376,42.8581 L72.4086,42.8581 L72.4086,46.9351 L56.6836,46.9351 L56.6836,42.8581 L62.3786,42.8581 L62.3786,16.4561 L56.6836,16.4561 L56.6836,12.3141 L67.0376,12.3141 L67.0376,16.9091 Z"
            id="Fill-5"
            fill="var(--title-color)"
          />
          <path
            d="M108.5703,17.1679 C113.4233,13.2849 115.0413,12.2489 118.7303,12.2489 L122.4833,12.2489 C125.7183,12.2489 127.1423,12.8319 128.4373,14.0619 C129.7963,15.4199 130.2493,17.2969 130.2493,20.9849 L130.2493,42.8589 L135.8143,42.8589 L135.8143,46.9359 L120.2183,46.9359 L120.2183,42.8589 L125.6543,42.8589 L125.6543,21.2439 C125.6543,19.2379 125.3303,18.2029 124.6183,17.4269 C123.9063,16.7149 123.1953,16.4559 120.6713,16.4559 L117.3713,16.4559 C114.5233,16.4559 112.4523,17.2329 108.8293,19.5619"
            id="Fill-7"
            fill="var(--title-color)"
          />
          <path
            d="M144.0278,27.457 L162.1468,27.457 C162.1468,20.339 161.5648,18.462 160.5938,17.491 C159.8818,16.715 158.9118,16.456 156.4528,16.456 L149.7218,16.456 C147.2628,16.456 146.3578,16.715 145.5158,17.491 C144.6098,18.462 144.0918,20.144 144.0278,27.457 L144.0278,27.457 Z M144.0278,31.34 C144.0918,39.234 144.6098,40.852 145.5808,41.759 C146.3578,42.6 147.2628,42.794 149.7218,42.794 L157.2938,42.794 C159.4938,42.794 160.3348,42.47 161.1118,41.759 C161.8238,40.981 162.1468,39.882 162.2768,36.322 L166.8708,36.322 C166.7418,41.629 166.1588,43.44 164.4768,45.123 C163.1828,46.418 161.5648,46.935 158.1998,46.935 L149.2698,46.935 C145.7098,46.935 143.7038,46.482 142.2158,44.994 C140.0158,42.794 139.3038,40.011 139.3038,29.593 C139.3038,19.238 140.0158,16.456 142.2158,14.256 C143.7038,12.767 145.7098,12.314 149.2698,12.314 L156.9048,12.314 C160.3998,12.314 162.5348,12.832 163.8948,14.19 C166.1588,16.456 166.8708,19.433 166.8708,29.398 L166.8708,30.563 C166.8708,31.081 166.6768,31.34 166.0948,31.34 L144.0278,31.34 Z"
            id="Fill-9"
            fill="var(--title-color)"
          />
          <path
            d="M187.7051,42.8584 L187.7051,46.9354 L171.5921,46.9354 L171.5921,42.8584 L177.2861,42.8584 L177.2861,16.4564 L171.5921,16.4564 L171.5921,12.3144 L181.9461,12.3144 L181.9461,17.9444 C186.1521,13.5444 187.7051,12.2494 190.6171,12.2494 L194.3701,12.2494 L194.3701,17.1674 L190.1001,17.1674 C187.4461,17.1674 185.6341,17.6204 181.9461,20.1444 L181.9461,42.8584 L187.7051,42.8584 Z"
            id="Fill-11"
            fill="var(--title-color)"
          />
          <path
            d="M215.0757,42.8584 L215.0757,46.9354 L198.9627,46.9354 L198.9627,42.8584 L204.6567,42.8584 L204.6567,16.4564 L198.9627,16.4564 L198.9627,12.3144 L209.3167,12.3144 L209.3167,17.9444 C213.5227,13.5444 215.0757,12.2494 217.9877,12.2494 L221.7407,12.2494 L221.7407,17.1674 L217.4707,17.1674 C214.8167,17.1674 213.0047,17.6204 209.3167,20.1444 L209.3167,42.8584 L215.0757,42.8584 Z"
            id="Fill-13"
            fill="var(--title-color)"
          />

          <g id="Clip-16" />
          <path
            d="M231.963,6.296 L237.528,6.296 L237.528,0.083 L231.963,0.083 L231.963,6.296 Z M226.851,46.935 L226.851,42.858 L232.675,42.858 L232.675,16.456 L226.851,16.456 L226.851,12.314 L237.334,12.314 L237.334,42.858 L242.899,42.858 L242.899,46.935 L226.851,46.935 Z"
            id="Fill-15"
            fill="var(--title-color)"
          />
          <path
            d="M249.9517,44.9941 C247.7517,42.7941 247.0397,40.0111 247.0397,29.5931 C247.0397,19.2381 247.7517,16.4561 249.9517,14.2561 C251.4397,12.7671 253.4457,12.3141 257.0057,12.3141 L265.5477,12.3141 C268.9117,12.3141 270.5307,12.8321 271.8237,14.1261 C273.4417,15.7441 274.0897,17.7501 274.2187,23.6391 L269.5597,23.6391 C269.4947,19.3681 269.1067,18.2031 268.3297,17.4911 C267.6187,16.7791 266.7117,16.5201 264.5117,16.5201 L257.5227,16.5201 C255.0637,16.5201 254.0937,16.7791 253.3167,17.5561 C252.2817,18.5911 251.7637,20.2731 251.7637,29.5931 C251.7637,38.9751 252.2817,40.6581 253.3167,41.6931 C254.0937,42.4701 255.0637,42.7281 257.5227,42.7281 L264.5767,42.7281 C266.7117,42.7281 267.7477,42.4701 268.5237,41.7591 C269.2357,40.9811 269.6237,39.4931 269.6887,35.2231 L274.2837,35.2231 C274.2187,41.6291 273.5717,43.4401 271.8887,45.1231 C270.6597,46.3521 268.9117,46.9351 265.6117,46.9351 L257.0057,46.9351 C253.4457,46.9351 251.4397,46.4821 249.9517,44.9941"
            id="Fill-17"
            fill="var(--title-color)"
          />
          <polygon
            id="Fill-18"
            fill="var(--title-color)"
            points="296.8008 29.0742 307.3488 42.8582 313.1088 42.8582 313.1088 46.9352 304.3718 46.9352 292.9828 31.4692 288.5178 31.4692 288.5178 42.8582 293.9538 42.8582 293.9538 46.9352 278.1638 46.9352 278.1638 42.8582 283.8588 42.8582 283.8588 4.4842 278.1638 4.4842 278.1638 0.3422 288.5178 0.3422 288.5178 27.4572 293.1768 27.4572 302.6248 16.3262 297.2538 16.3262 297.2538 12.3142 312.7198 12.3142 312.7198 16.3902 307.8018 16.3902"
          />
          <polygon
            id="Fill-19"
            fill="var(--title-color)"
            points="326.36 46.935 363.608 46.935 363.608 42.858 326.36 42.858"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Logo;
