import Header from "../../../../src/components/Header";
import Quote from "../../../../src/components/Quote";
import Layout, { PageLayout } from "../../../../src/components/Layout";
import { MaybeStickyTitle, Title, TitleDescription } from "../../../../src/components/Titles";
import Nest from "../../../../src/components/Nest";
import { ImageItem, Contain, LinksLayout } from "../../../../src/components/ImageContainer";
import { Feedback, Calendar } from "../../../../src/components/Social";
import * as React from 'react';
export default {
  Header,
  Quote,
  Layout,
  PageLayout,
  MaybeStickyTitle,
  Title,
  TitleDescription,
  Nest,
  ImageItem,
  Contain,
  LinksLayout,
  Feedback,
  Calendar,
  React
};