import React from "react";
import styled from "styled-components";
import DoubleLine from "./DoubleLine";

const QuoteLayout = styled.div`
  font-size: 16px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.5;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    flex-direction: column;
  }
`;

const QuoteContent = styled.span`
  color: var(--body-text-color);
`;

const QuoteSource = styled.span`
  color: var(--body-text-muted-color);
`;

const Quote = ({ content, source }) => (
  <div>
    <DoubleLine />
    <QuoteLayout>
      <QuoteContent>&quot;{content}&quot;</QuoteContent>
      <QuoteSource>&mdash; {source}</QuoteSource>
    </QuoteLayout>
    <DoubleLine />
  </div>
);

export default Quote;
