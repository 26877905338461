import React from "react";
import { Helmet } from "react-helmet";

import "./Head.css";

const Head = ({
  title = "",
  excerpt = "Systems thinking, engineering principles & general postulations. In pursuit of the timeless and great as it pertains to accomplishing amazing things with other people.",
  slug = "",
  children,
}) => (
  <Helmet title={`Merrick Christensen ${title === "" ? "" : `- ${title}`}`}>
    <link rel="canonical" />
    <meta name="description" content={excerpt} />
    <meta
      name="keywords"
      content="code, systems thinking, javascript, react, engineering principles, abstractions, techncial teams, open web"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      property="og:url"
      content={`https://www.merrickchristensen.com${slug}`}
    />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={title} />
    <meta
      property="og:image"
      content="https://www.merrickchristensen.com/assets/images/og.png"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Oswald:700"
      rel="stylesheet"
    />
    {children}
  </Helmet>
);

export default Head;
