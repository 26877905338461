import React, { useState } from "react";
import { VictoryLine, VictoryChart, VictoryAxis } from "victory";
import CustomVictoryTheme from "../constants/VictoryTheme";
import styled from "styled-components";

const Input = styled.input`
  background-color: var(--background-color);
  color: var(--body-text-color);
  border: 1px solid var(--element-muted-color);
  padding: 4px;

  &:focus {
    border-color: var(--bright-color);
    outline: none;
  }
`;

const ChartControls = styled.div`
  display: grid;
  grid-gap: 8px;
  padding-bottom: 32px;
`;

const arrayOf = (n) => {
  const result = [];
  for (let i = 0; i < n; i++) {
    result.push(i);
  }
  return result;
};

const CostOfAlignment = ({
  initNumberOfPeople = 10,
  initEffectiveness = 0.8,
  controls = false,
}) => {
  const [numberOfPeople, setNumberOfPeople] = useState(initNumberOfPeople);
  const [alignmentEffectiveness, setAlignmentEffectiveness] = useState(
    initEffectiveness
  );
  const steps = arrayOf(5 + numberOfPeople / 10);
  const peopleGrowth = steps.map((i) => {
    const peopleForStep = (i * numberOfPeople) / steps.length;
    return {
      x: peopleForStep,
      y: peopleForStep,
    };
  });

  const costOfAlignment = steps.map((i) => {
    const peopleForStep = (i * numberOfPeople) / steps.length;
    return {
      x: peopleForStep,
      y:
        ((peopleForStep * (peopleForStep - 1)) / 2) *
        (1 - (alignmentEffectiveness > 0.99 ? 0.99 : alignmentEffectiveness)),
    };
  });

  return (
    <div>
      <VictoryChart theme={CustomVictoryTheme} animate>
        <VictoryAxis label="Number of People" />
        <VictoryLine
          data={peopleGrowth}
          interpolation="natural"
          style={{
            data: {
              stroke: "var(--body-text-color)",
            },
          }}
          labels={(d) =>
            d.index === String(Math.floor(steps.length - 1))
              ? "Number of People"
              : null
          }
        />
        <VictoryAxis label="Cost of Alignment" dependentAxis />
        <VictoryLine
          data={costOfAlignment}
          interpolation="natural"
          style={{
            data: {
              stroke: "var(--bright-color)",
            },
          }}
          labels={(d) =>
            d.index === String(Math.floor(steps.length - 1))
              ? "Cost of Alignment"
              : null
          }
        />
      </VictoryChart>
      {controls && (
        <ChartControls>
          <div>Number of People</div>
          <Input
            type="number"
            min="2"
            max="800"
            value={numberOfPeople}
            onChange={(e) => {
              setNumberOfPeople(e.target.value);
            }}
          />
          <div>Alignment Effectiveness, 0 terribad - 1 world class</div>
          <Input
            type="number"
            value={alignmentEffectiveness}
            min="0"
            max="1"
            step="0.1"
            onChange={(e) => {
              setAlignmentEffectiveness(e.target.value);
            }}
          />
        </ChartControls>
      )}
    </div>
  );
};

export default CostOfAlignment;
