import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import avatar from "./avatar.png";
import Logo from "./Logo";

const HeaderLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  line-height: 1;

  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    flex-direction: column;
    padding: 24px 0;
  }
`;

const AvatarLayout = styled.div`
  padding-right: 24px;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    padding-right: 16px;
  }
`;
const activeLinkStyle = { opacity: 0.4 };

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Navigation = styled.nav`
  display: flex;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    padding-top: 24px;
  }
`;

const NavigationLink = styled(Link)`
  display: block;
  color: var(--header-title-color);
  text-decoration: none;
  font-size: 18px;
  margin: 0 16px;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.4;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const Image = styled.img`
  margin-bottom: 0;
`;

const Header = () => (
  <HeaderLayout>
    <LogoLink to="/">
      <AvatarLayout>
        <Image
          src={avatar}
          width={40}
          height={40}
          alt="Merrick Christensen's Avatar"
        />
      </AvatarLayout>
      <Logo height={34} alt="Merrick Christensen" />
    </LogoLink>
    <Navigation>
      <NavigationLink
        to="/"
        getProps={({ location }) =>
          location.pathname === "/" || location.pathname.match(/^\/articles/)
            ? { style: activeLinkStyle }
            : null
        }
      >
        blog
      </NavigationLink>
      <NavigationLink
        to="/work"
        getProps={({ isPartiallyCurrent }) =>
          isPartiallyCurrent ? { style: activeLinkStyle } : null
        }
      >
        work
      </NavigationLink>
      <NavigationLink
        to="/follow"
        getProps={({ isPartiallyCurrent }) =>
          isPartiallyCurrent ? { style: activeLinkStyle } : null
        }
      >
        follow
      </NavigationLink>
    </Navigation>
  </HeaderLayout>
);

export default Header;
