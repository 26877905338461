import React from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Description = styled.div`
  padding-top: 8px;
  color: var(--body-text-color);
  text-align: center;
  font-size: 14px;
`;

export const ImageItem = ({ href, image, description }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <ImageContainer>{image}</ImageContainer>
    <Description>{description}</Description>
  </a>
);

export const LinksLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 40px;
  justify-content: space-between;
  align-items: self-start;
`;

export const Contain = styled.div`
  max-width: 664px;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    padding-top: 16px;
  }
`;
