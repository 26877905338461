import styled from "styled-components";

export const Title = styled.div`
  font: 70px Oswald;
  color: var(--title-color);
  font-weight: 700;
  margin-top: calc(-0.75rem - 8px);
  text-transform: uppercase;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    font-size: 32px;
    margin-top: 0;
  }
`;

export const TitleDescription = styled.div`
  font-size: 20px;
  color: var(--sub-title-color);
  padding-top: 24px;
  max-width: 80%;
  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    padding-top: 8px;
    max-width: 100%;
  }
`;

export const MaybeStickyTitle = styled.div`
  position: sticky;
  top: 0;

  @media (max-width: ${({ theme }) => theme.verticalLayout}px) {
    position: static;
    padding-bottom: 16px;
  }
`;

export const SectionTitle = styled.div`
  font-size: 20px;
  color: var(--title-color);
  padding-bottom: 16px;
`;
