// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-abstraction-mdx": () => import("./../../../src/pages/articles/abstraction.mdx" /* webpackChunkName: "component---src-pages-articles-abstraction-mdx" */),
  "component---src-pages-articles-angular-cheat-sheet-mdx": () => import("./../../../src/pages/articles/angular-cheat-sheet.mdx" /* webpackChunkName: "component---src-pages-articles-angular-cheat-sheet-mdx" */),
  "component---src-pages-articles-build-angular-with-requirejs-mdx": () => import("./../../../src/pages/articles/build-angular-with-requirejs.mdx" /* webpackChunkName: "component---src-pages-articles-build-angular-with-requirejs-mdx" */),
  "component---src-pages-articles-cost-of-consensus-mdx": () => import("./../../../src/pages/articles/cost-of-consensus.mdx" /* webpackChunkName: "component---src-pages-articles-cost-of-consensus-mdx" */),
  "component---src-pages-articles-dom-libraries-mdx": () => import("./../../../src/pages/articles/dom-libraries.mdx" /* webpackChunkName: "component---src-pages-articles-dom-libraries-mdx" */),
  "component---src-pages-articles-extensible-web-summit-mdx": () => import("./../../../src/pages/articles/extensible-web-summit.mdx" /* webpackChunkName: "component---src-pages-articles-extensible-web-summit-mdx" */),
  "component---src-pages-articles-function-as-child-components-mdx": () => import("./../../../src/pages/articles/function-as-child-components.mdx" /* webpackChunkName: "component---src-pages-articles-function-as-child-components-mdx" */),
  "component---src-pages-articles-gruntjs-workflow-mdx": () => import("./../../../src/pages/articles/gruntjs-workflow.mdx" /* webpackChunkName: "component---src-pages-articles-gruntjs-workflow-mdx" */),
  "component---src-pages-articles-headless-user-interface-components-mdx": () => import("./../../../src/pages/articles/headless-user-interface-components.mdx" /* webpackChunkName: "component---src-pages-articles-headless-user-interface-components-mdx" */),
  "component---src-pages-articles-introducing-stylemanager-mdx": () => import("./../../../src/pages/articles/introducing-stylemanager.mdx" /* webpackChunkName: "component---src-pages-articles-introducing-stylemanager-mdx" */),
  "component---src-pages-articles-javascript-dependency-injection-mdx": () => import("./../../../src/pages/articles/javascript-dependency-injection.mdx" /* webpackChunkName: "component---src-pages-articles-javascript-dependency-injection-mdx" */),
  "component---src-pages-articles-json-lisp-mdx": () => import("./../../../src/pages/articles/json-lisp.mdx" /* webpackChunkName: "component---src-pages-articles-json-lisp-mdx" */),
  "component---src-pages-articles-lazy-evaluation-in-javascript-mdx": () => import("./../../../src/pages/articles/lazy-evaluation-in-javascript.mdx" /* webpackChunkName: "component---src-pages-articles-lazy-evaluation-in-javascript-mdx" */),
  "component---src-pages-articles-learn-js-mdx": () => import("./../../../src/pages/articles/learn-js.mdx" /* webpackChunkName: "component---src-pages-articles-learn-js-mdx" */),
  "component---src-pages-articles-lessons-from-lean-manufacturing-mdx": () => import("./../../../src/pages/articles/lessons-from-lean-manufacturing.mdx" /* webpackChunkName: "component---src-pages-articles-lessons-from-lean-manufacturing-mdx" */),
  "component---src-pages-articles-mvstar-libraries-and-frameworks-mdx": () => import("./../../../src/pages/articles/mvstar-libraries-and-frameworks.mdx" /* webpackChunkName: "component---src-pages-articles-mvstar-libraries-and-frameworks-mdx" */),
  "component---src-pages-articles-no-code-is-eating-software-mdx": () => import("./../../../src/pages/articles/no-code-is-eating-software.mdx" /* webpackChunkName: "component---src-pages-articles-no-code-is-eating-software-mdx" */),
  "component---src-pages-articles-react-angular-di-mdx": () => import("./../../../src/pages/articles/react-angular-di.mdx" /* webpackChunkName: "component---src-pages-articles-react-angular-di-mdx" */),
  "component---src-pages-articles-react-dependency-injection-mdx": () => import("./../../../src/pages/articles/react-dependency-injection.mdx" /* webpackChunkName: "component---src-pages-articles-react-dependency-injection-mdx" */),
  "component---src-pages-articles-scenario-vs-problem-solving-mdx": () => import("./../../../src/pages/articles/scenario-vs-problem-solving.mdx" /* webpackChunkName: "component---src-pages-articles-scenario-vs-problem-solving-mdx" */),
  "component---src-pages-articles-single-state-tree-mdx": () => import("./../../../src/pages/articles/single-state-tree.mdx" /* webpackChunkName: "component---src-pages-articles-single-state-tree-mdx" */),
  "component---src-pages-articles-stateful-semantic-diffing-mdx": () => import("./../../../src/pages/articles/stateful-semantic-diffing.mdx" /* webpackChunkName: "component---src-pages-articles-stateful-semantic-diffing-mdx" */),
  "component---src-pages-articles-swift-mdx": () => import("./../../../src/pages/articles/swift.mdx" /* webpackChunkName: "component---src-pages-articles-swift-mdx" */),
  "component---src-pages-articles-using-webflow-and-netlify-mdx": () => import("./../../../src/pages/articles/using-webflow-and-netlify.mdx" /* webpackChunkName: "component---src-pages-articles-using-webflow-and-netlify-mdx" */),
  "component---src-pages-follow-js": () => import("./../../../src/pages/follow.js" /* webpackChunkName: "component---src-pages-follow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-thoughts-action-revelation-and-obedience-mdx": () => import("./../../../src/pages/thoughts/action-revelation-and-obedience.mdx" /* webpackChunkName: "component---src-pages-thoughts-action-revelation-and-obedience-mdx" */),
  "component---src-pages-thoughts-expectations-dissapointments-and-trust-mdx": () => import("./../../../src/pages/thoughts/expectations-dissapointments-and-trust.mdx" /* webpackChunkName: "component---src-pages-thoughts-expectations-dissapointments-and-trust-mdx" */),
  "component---src-pages-thoughts-free-will-mdx": () => import("./../../../src/pages/thoughts/free-will.mdx" /* webpackChunkName: "component---src-pages-thoughts-free-will-mdx" */),
  "component---src-pages-thoughts-gods-love-mdx": () => import("./../../../src/pages/thoughts/gods-love.mdx" /* webpackChunkName: "component---src-pages-thoughts-gods-love-mdx" */),
  "component---src-pages-thoughts-js": () => import("./../../../src/pages/thoughts.js" /* webpackChunkName: "component---src-pages-thoughts-js" */),
  "component---src-pages-thoughts-kindness-untempered-is-cruelty-mdx": () => import("./../../../src/pages/thoughts/kindness-untempered-is-cruelty.mdx" /* webpackChunkName: "component---src-pages-thoughts-kindness-untempered-is-cruelty-mdx" */),
  "component---src-pages-thoughts-law-of-the-harvest-mdx": () => import("./../../../src/pages/thoughts/law-of-the-harvest.mdx" /* webpackChunkName: "component---src-pages-thoughts-law-of-the-harvest-mdx" */),
  "component---src-pages-thoughts-obedience-mdx": () => import("./../../../src/pages/thoughts/obedience.mdx" /* webpackChunkName: "component---src-pages-thoughts-obedience-mdx" */),
  "component---src-pages-thoughts-on-anxiety-mdx": () => import("./../../../src/pages/thoughts/on-anxiety.mdx" /* webpackChunkName: "component---src-pages-thoughts-on-anxiety-mdx" */),
  "component---src-pages-thoughts-on-duty-mdx": () => import("./../../../src/pages/thoughts/on-duty.mdx" /* webpackChunkName: "component---src-pages-thoughts-on-duty-mdx" */),
  "component---src-pages-thoughts-on-people-pleasing-mdx": () => import("./../../../src/pages/thoughts/on-people-pleasing.mdx" /* webpackChunkName: "component---src-pages-thoughts-on-people-pleasing-mdx" */),
  "component---src-pages-thoughts-on-suffering-mdx": () => import("./../../../src/pages/thoughts/on-suffering.mdx" /* webpackChunkName: "component---src-pages-thoughts-on-suffering-mdx" */),
  "component---src-pages-thoughts-on-sympathy-mdx": () => import("./../../../src/pages/thoughts/on-sympathy.mdx" /* webpackChunkName: "component---src-pages-thoughts-on-sympathy-mdx" */),
  "component---src-pages-thoughts-pain-as-expensive-and-effective-education-mdx": () => import("./../../../src/pages/thoughts/pain-as-expensive-and-effective-education.mdx" /* webpackChunkName: "component---src-pages-thoughts-pain-as-expensive-and-effective-education-mdx" */),
  "component---src-pages-thoughts-the-worth-of-a-soul-mdx": () => import("./../../../src/pages/thoughts/the-worth-of-a-soul.mdx" /* webpackChunkName: "component---src-pages-thoughts-the-worth-of-a-soul-mdx" */),
  "component---src-pages-tools-expected-value-tsx": () => import("./../../../src/pages/tools/expected-value.tsx" /* webpackChunkName: "component---src-pages-tools-expected-value-tsx" */),
  "component---src-pages-user-guide-mdx": () => import("./../../../src/pages/user-guide.mdx" /* webpackChunkName: "component---src-pages-user-guide-mdx" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

