import React from "react";
import { ThemeProvider } from "styled-components";
import * as theme from "../constants/theme";

const Context = ({ children }) => (
  <ThemeProvider theme={theme}>
    <div>{children}</div>
  </ThemeProvider>
);

export default Context;
